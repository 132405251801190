<template>
  <div class="d-flex flex-column flex-root">
    <div
      id="kt_login"
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #39576c">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/logo-itograss.svg" class="max-h-70px" alt="">
          </a>
        </div>
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              id="kt_login_signin_form"
              class="form"
              novalidate="novalidate"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Usuário</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input
                    ref="username"
                    v-model="form.username"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="username"
                  >
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Senha</label>
                </div>
                <div id="example-input-group-2">
                  <input
                    ref="password"
                    v-model="form.password"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  id="kt_login_signin_submit"
                  ref="kt_login_signin_submit"
                  :disabled="disableSubmitButton"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Swal from 'sweetalert2'

import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core'
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger'
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap'
import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton'
import KTUtil from '@/assets/js/components/util'
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module'

export default {
  name: 'Login',
  data: () => ({
    disableSubmitButton: false,
    form: {
      username: '',
      password: '',
    },
  }),
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(['currentUser']),
  },
  mounted () {
    const signinForm = KTUtil.getById('kt_login_signin_form')

    this.fv = formValidation(signinForm, {
      fields: {
        username: {
          validators: {
            notEmpty: {
              message: 'Insira seu usuário',
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: 'Insira sua senha',
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    })
  },
  methods: {
    onSubmitLogin () {
      this.fv.validate()
        .then((status) => {
          if (status === 'Valid') {
            var username = this.form.username
            var password = this.form.password
            this.disableSubmitButton = true

            // clear existing errors
            this.$store.dispatch(LOGOUT)

            // set spinner to submit button
            const submitButton = this.$refs.kt_login_signin_submit
            submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

            // send login request
            this.$store
              .dispatch(LOGIN, { username, password })
            // go to which page after successfully login
              .then(() => {
                this.$router.push({ path: '/' })
              })
              .catch((data) => {
                this.disableSubmitButton = false

                submitButton.classList.remove(
                  'spinner',
                  'spinner-light',
                  'spinner-right',
                )

                Swal.fire({
                  title: '',
                  text: 'Usuário ou senha inválidos!',
                  icon: 'error',
                  confirmButtonClass: 'btn btn-secondary',
                  heightAuto: false,
                })
              })

            return true
          }

          Swal.fire({
            title: '',
            text: 'Necessário preencher todos os campos!',
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary',
            heightAuto: false,
          })

          return false
        })
    },
  },
}
</script>
